package com.viralinternetsensations.app.model

import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Sensation(
    @SerialName("id") val id: Int? = null,
    @Serializable(LocalDateTimeSerializer::class)
    @SerialName("date") val date: LocalDateTime? = null,
    @SerialName("title") val title: String? = null,
    @SerialName("description") val description: String? = null,
    @SerialName("imageUrl") val imageUrl: String? = null,
    @SerialName("detailUrl") val detailUrl: String? = null,
    @SerialName("tags") val tags: ArrayList<String?>? = null,
)