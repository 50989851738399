package com.viralinternetsensations.app

import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import org.w3c.dom.events.Event

class JsScreenSizeProvider: ScreenSizeProvider {
    override val screenSizeState: ScreenSizeState = ScreenSizeState()

    init {
        // Set the initial state
        updateScreenSize()
        // Add a listener for resize events
        window.addEventListener("resize", ::handleResize)
    }

    private fun handleResize(event: Event) {
        MainScope().launch {
            screenSizeState.isSmallScreen = window.innerWidth < 600
        }
    }

    private fun updateScreenSize() {
        MainScope().launch {
            screenSizeState.isSmallScreen = window.innerWidth < 600
        }
    }
}

actual fun getScreenSizeProvider(): ScreenSizeProvider = JsScreenSizeProvider()