package com.viralinternetsensations.app

import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.rememberUpdatedState
import coil3.compose.setSingletonImageLoaderFactory

@Composable
fun App(screenSizeProvider: ScreenSizeProvider) {
    setSingletonImageLoaderFactory { getAsyncImageLoader(it) }

    MaterialTheme {
        /*var showContent by remember { mutableStateOf(false) }
        Column(Modifier.fillMaxWidth(), horizontalAlignment = Alignment.CenterHorizontally) {
            Button(onClick = { showContent = !showContent }) {
                Text("Click me!")
            }
            AnimatedVisibility(showContent) {
                val greeting = remember { "hi" }
                TextButton(
                    onClick = { openUrl("https://google.com") }
                ) {
                    Text("MORE INFO")
                }
            }
        }*/

        val isSmallScreen by rememberUpdatedState(screenSizeProvider.screenSizeState.isSmallScreen)
        TimelineView(isSmallScreen)
    }
}

internal expect fun openUrl(url: String?)