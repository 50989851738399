package com.viralinternetsensations.app

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.horizontalScroll
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.layout.wrapContentSize
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.CornerSize
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.ChipColors
import androidx.compose.material3.ElevatedCard
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.LocalMinimumInteractiveComponentEnforcement
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.RadioButton
import androidx.compose.material3.Scaffold
import androidx.compose.material3.SuggestionChip
import androidx.compose.material3.SuggestionChipDefaults
import androidx.compose.material3.Text
import androidx.compose.material3.VerticalDivider
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.draw.blur
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.constraintlayout.compose.ConstraintLayout
import androidx.constraintlayout.compose.Dimension
import coil3.compose.AsyncImage
import com.viralinternetsensations.app.model.Sensation
import com.viralinternetsensations.nonScaledSp
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.Month

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun TimelineView(isSmallScreen: Boolean) {

    val sensations = listOf(
        Sensation(1, LocalDateTime(2024, Month.JUNE, 20, 0,0, 0), "Crawly Goat", "This is a description for the GOAT that is known as Crawly", "https://viralinternetsensations.com/img/crawley.png", "https://www.tiktok.com/@crawly_possessed", arrayListOf("gnome", "elf", "tinMan", "soundtrack")),
        Sensation(2, LocalDateTime(2024, Month.JUNE, 10, 0,0, 0), "Hawk Tuah", "This is a description for the girl that is known for Hawk tuah", "https://viralinternetsensations.com/img/hawk.jpg", "https://www.instagram.com/hay_welch", arrayListOf("spitOn", "thatThang")),
        Sensation(3, LocalDateTime(2024, Month.FEBRUARY, 1, 0,0, 0), "Err Brudda What Is That", "This is a description for the brudda that is known for Err Brudda What Is That", "https://viralinternetsensations.com/img/err.png", "https://www.instagram.com/mohamed__hoblos", arrayListOf("err", "poo", "mike")),
        Sensation(4, LocalDateTime(2021, Month.NOVEMBER, 1, 0,0, 0), "Hasbulla", "This is a description for the kid that is known as Hasbulla", "https://viralinternetsensations.com/img/hasbulla.jpg", "https://www.instagram.com/hasbulla.hushetskiy", arrayListOf("mini", "UFC", "Desi hasbulla")),
    ).sortedByDescending { it.date }

    Scaffold(
        content = { innerPadding ->
            Box(
                modifier = Modifier
                    .fillMaxSize()
                    .padding(innerPadding)
                    .background(pageBackground)
            ) {
                Column(modifier = Modifier.fillMaxSize().verticalScroll(rememberScrollState()).padding(horizontal = 12.dp)) {
                    Spacer(modifier = Modifier.height(64.dp))
                    Text(
                        modifier = Modifier.fillMaxWidth().wrapContentHeight().padding(top = 12.dp),
                        text = "Welcome to the digital",
                        overflow = TextOverflow.Ellipsis,
                        fontSize = 20.sp.nonScaledSp,
                        color = Color.White,
                        fontWeight = FontWeight.Bold,
                        textAlign = TextAlign.Center,
                    )
                    Text(
                        modifier = Modifier.fillMaxWidth().wrapContentHeight().padding(top = 12.dp),
                        text = "Museum of Viral Internet Sensations",
                        overflow = TextOverflow.Ellipsis,
                        fontSize = 28.sp.nonScaledSp,
                        color = primary,
                        fontWeight = FontWeight.Bold,
                        textAlign = TextAlign.Center,
                    )
                    Text(
                        modifier = Modifier.fillMaxWidth().wrapContentHeight().padding(top = 12.dp),
                        text = "The archive of everything that the people of the internet have made viral, and will ever make viral",
                        overflow = TextOverflow.Ellipsis,
                        fontSize = 20.sp.nonScaledSp,
                        color = Color.White,
                        fontStyle = FontStyle.Italic,
                        fontWeight = FontWeight.Bold,
                        textAlign = TextAlign.Center,
                    )


                    ConstraintLayout(modifier = Modifier.fillMaxWidth().wrapContentHeight().padding(top = 42.dp)) {
                        // Create references for the composables to constrain
                        val references = sensations.map { createRef() }
                        val refVertical = createRef()


                        VerticalDivider(modifier = Modifier.constrainAs(refVertical) {
                            top.linkTo(parent.top)
                            bottom.linkTo(parent.bottom)
                            start.linkTo(parent.start)
                            end.linkTo(parent.end)
                            height = Dimension.fillToConstraints
                        }, thickness = 4.dp, color = primaryTint)


                        /*val (button, text, vertical) = createRefs()
                        val middleGuideline = createGuidelineFromStart(0.5f)*/




                        sensations.forEachIndexed { index, sensation ->
                            val isFirst = index == 0
                            val isEven = index % 2 == 0

                            val shape = RoundedCornerShape(8.dp)

                            ConstraintLayout(modifier = Modifier.constrainAs(references[index]) {
                                if (isFirst) top.linkTo(parent.top) else top.linkTo(references[index.minus(1)].bottom, margin = 6.dp) // Position based on index
                                /*start.linkTo(if (isEven) refVertical.end else parent.start, margin = 6.dp)
                                end.linkTo(if (isEven) parent.end else refVertical.start, margin = 6.dp)
                                width = Dimension.fillToConstraints*/
                            }.fillMaxWidth().wrapContentHeight()) {
                                val middleGuideline = createGuidelineFromStart(0.5f)
                                val (dateContainer, middleCircleContainer, card) = createRefs()

                                val monthYearString = "${sensation.date?.month?.name?.take(3)?.lowercase()?.replaceFirstChar { it.uppercase() }} ${sensation.date?.year}"



                                /*Box(
                                    modifier = Modifier
                                        .constrainAs(test) {
                                            // Align the center of the Box with the guideline
                                            start.linkTo(middleGuideline)
                                            end.linkTo(middleGuideline)
                                            // Adjust the vertical alignment as needed
                                            top.linkTo(parent.top)
                                            bottom.linkTo(parent.bottom)
                                        }
                                        .size(100.dp)
                                        .background(Color.Green)
                                )*/


                                CompositionLocalProvider(LocalMinimumInteractiveComponentEnforcement provides false) {
                                    Card(modifier = Modifier.constrainAs(dateContainer) {
                                        top.linkTo(parent.top)
                                        bottom.linkTo(parent.bottom)
                                        if (isEven) end.linkTo(middleCircleContainer.start/*, margin = 16.dp*/) else start.linkTo(middleCircleContainer.end/*, margin = 16.dp*/)
                                    }.wrapContentSize(), elevation = CardDefaults.cardElevation(defaultElevation = 0.dp), colors = CardDefaults.cardColors(containerColor = cardBackground),
                                        shape = shape.copy(topStart = if (isEven) shape.topStart else CornerSize(0.dp), bottomStart = if (isEven) shape.bottomStart else CornerSize(0.dp), topEnd = if (isEven) CornerSize(0.dp) else shape.topEnd, bottomEnd = if (isEven) CornerSize(0.dp) else shape.bottomEnd),
                                        onClick = {
                                            openUrl(sensation.detailUrl)
                                        }) {
                                        Text(
                                            modifier = Modifier.wrapContentSize().padding(8.dp),
                                            text = monthYearString,
                                            minLines = 1,
                                            maxLines = 1,
                                            overflow = TextOverflow.Ellipsis,
                                            fontSize = 18.sp,
                                            color = Color.White,
                                            fontWeight = FontWeight.Bold,
                                            textAlign = if (isEven) TextAlign.End else TextAlign.Start,
                                        )
                                    }
                                }


                                ConstraintLayout(
                                    modifier = Modifier
                                        .constrainAs(middleCircleContainer) {
                                            top.linkTo(dateContainer.top)
                                            bottom.linkTo(dateContainer.bottom)
                                            start.linkTo(middleGuideline)
                                            end.linkTo(middleGuideline)
                                            height = Dimension.fillToConstraints
                                        }
                                        .width(24.dp)
                                        .background(cardBackground)
                                ) {
                                    val (innerCircle, innerCircleGlow, innerDivider) = createRefs()

                                    VerticalDivider(modifier = Modifier.constrainAs(innerDivider) {
                                        top.linkTo(parent.top)
                                        bottom.linkTo(parent.bottom)
                                        start.linkTo(parent.start)
                                        end.linkTo(parent.end)
                                        height = Dimension.fillToConstraints
                                    }, thickness = 4.dp, color = primaryTint)


                                    Box(
                                        modifier = Modifier.constrainAs(innerCircleGlow) {
                                            top.linkTo(parent.top)
                                            bottom.linkTo(parent.bottom)
                                            start.linkTo(parent.start)
                                            end.linkTo(parent.end)
                                            height = Dimension.ratio("1:1")
                                            width = Dimension.ratio("1:1")
                                        }.background(
                                            brush = Brush.radialGradient(
                                                colors = listOf(Color.Transparent, primaryTint, Color.Transparent),
                                                radius = 24.dp.value
                                            ),
                                            shape = CircleShape
                                        )
                                    )

                                    Box(
                                        modifier = Modifier.constrainAs(innerCircle) {
                                            top.linkTo(parent.top)
                                            bottom.linkTo(parent.bottom)
                                            start.linkTo(parent.start)
                                            end.linkTo(parent.end)
                                            height = Dimension.ratio("1:1")
                                            width = Dimension.ratio("1:1")
                                        }.background(
                                            brush = Brush.radialGradient(
                                                colors = listOf(Color.White, Color.White, cardBackground, Color.Transparent),
                                                radius = 24.dp.value
                                            ),
                                            shape = CircleShape
                                        )
                                    )
                                }


                                Box(modifier = Modifier.constrainAs(card) {
                                    start.linkTo(if (isEven) middleCircleContainer.end else parent.start/*, margin = 16.dp*/)
                                    end.linkTo(if (isEven) parent.end else middleCircleContainer.start/*, margin = 16.dp*/)
                                    width = Dimension.fillToConstraints
                                }.wrapContentHeight()/*.alpha(0.1f)*/) { // had to add box here as centering other div was buggy
                                    Card(modifier = Modifier.fillMaxWidth().wrapContentHeight(), elevation = CardDefaults.cardElevation(defaultElevation = 0.dp), colors = CardDefaults.cardColors(containerColor = cardBackground),
                                        shape = shape,
                                        onClick = {
                                            openUrl(sensation.detailUrl)
                                        }) {


                                        ConstraintLayout(modifier = Modifier.fillMaxWidth().wrapContentHeight()) {
                                            val (image, content) = createRefs()

                                            AsyncImage(
                                                modifier = Modifier.constrainAs(image) {
                                                    top.linkTo(parent.top)
                                                    bottom.linkTo(parent.bottom)
                                                    if (isEven) start.linkTo(parent.start) else end.linkTo(parent.end)
                                                }.size(122.dp).padding(8.dp).clip(shape),
                                                //model = "https://i.imgur.com/ypm5f03.jpeg",
                                                model = sensation.imageUrl,
                                                contentScale = ContentScale.Crop,
                                                contentDescription = null // null because sensation.title is already visible
                                            )

                                            Column(modifier = Modifier.constrainAs(content) {
                                                top.linkTo(parent.top)
                                                bottom.linkTo(parent.bottom)
                                                start.linkTo(if (isEven) image.end else parent.start)
                                                end.linkTo(if (isEven) parent.end else image.start)
                                                width = Dimension.fillToConstraints
                                            }.wrapContentHeight().padding(8.dp), verticalArrangement = Arrangement.spacedBy(space = 4.dp)) {
                                                Text(
                                                    modifier = Modifier.fillMaxWidth().wrapContentHeight(),
                                                    text = sensation.title.orEmpty(),
                                                    minLines = 1,
                                                    maxLines = 1,
                                                    overflow = TextOverflow.Ellipsis,
                                                    fontSize = 18.sp.nonScaledSp,
                                                    color = Color.White,
                                                    fontWeight = FontWeight.Bold,
                                                    textAlign = if (isEven) TextAlign.Start else TextAlign.End,
                                                )

                                                Text(
                                                    modifier = Modifier.fillMaxWidth().wrapContentHeight(),
                                                    text = sensation.description.orEmpty(),
                                                    minLines = 1,
                                                    maxLines = 3,
                                                    fontSize = 16.sp,
                                                    color = Color.White,
                                                    overflow = TextOverflow.Ellipsis,
                                                    textAlign = if (isEven) TextAlign.Start else TextAlign.End,
                                                )

                                                Row(
                                                    modifier = Modifier.fillMaxWidth().wrapContentHeight().horizontalScroll(rememberScrollState()),
                                                    horizontalArrangement = Arrangement.spacedBy(
                                                        space = 4.dp,
                                                        alignment = if (isEven) Alignment.Start else Alignment.End
                                                    ),
                                                    verticalAlignment = Alignment.CenterVertically,
                                                ) {
                                                    val chips = sensation.tags?.filter { !it.isNullOrEmpty() }//?.apply { if (!isEven) reversed() }
                                                    //if (chips.isNullOrEmpty()) SuggestionChip(modifier = Modifier.alpha(0f), label = { Text("") }, onClick = {})
                                                    chips?.forEach {
                                                        CompositionLocalProvider(LocalMinimumInteractiveComponentEnforcement provides false) {
                                                            SuggestionChip(enabled = false, /*border = SuggestionChipDefaults.suggestionChipBorder(true).copy(), */colors = SuggestionChipDefaults.suggestionChipColors().copy(disabledContainerColor = chipBackground, disabledLabelColor = Color.White), label = { Text(it.orEmpty(), minLines = 1, maxLines = 1) }, onClick = {})
                                                        }
                                                    }
                                                }
                                            }

                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    )
}