import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.window.CanvasBasedWindow
import com.viralinternetsensations.app.App
import com.viralinternetsensations.app.getScreenSizeProvider
import org.jetbrains.skiko.wasm.onWasmReady

@OptIn(ExperimentalComposeUiApi::class)
fun main() {
    onWasmReady {
        CanvasBasedWindow(title = "Museum Of Viral Internet Sensations", canvasElementId = "ComposeTarget") {
            App(getScreenSizeProvider())
        }
    }
}
